import { Link } from "react-router-dom";
import '../PrivacyPolicy/PrivacyPolicy.scss';
import HeaderMinimal from "../../components/HeaderMinimal";
import { POLICY_URL } from '../../utils/constants';
import { EULA_URL } from '../../utils/constants';

const EULA = () => {
    return (
        <>
            <HeaderMinimal/>
            <div className="document_container">
                <div className={'back'}>
                    <Link to="/">← BACK</Link>
                </div>
                <div className="privacy_top">
                    <div className="privacy_h1">END-USER LICENSE AGREEMENT</div>
                </div>
                <div className="privacy_wrapper">
                    <div className="privacy_element">
                        <p className="update_date">Revised December 20, 2024</p>
                        <div className="btn_download">
                            <a href={EULA_URL} rel="noreferrer"
                               target="_blank">Download PDF</a>
                        </div>
                        <text>
                            <p>End-User License Agreement (the <b>“Agreement”</b>) constitutes an agreement between
                                private limited
                                company (Ltd) “CarX Technologies EU”
                                (<b>“CarX Technologies”</b>, <b>“we”</b>)
                                and you or your legal representative (the <b>“User”</b>, <b>“you”</b>). By providing
                                your consent in the forms in our Games or by continuing
                                to use our Games or Website (as defined below), you accept the terms of this Agreement.
                            </p>
                            <p>If you disagree with the terms of this Agreement or any future version hereof, you should
                                stop using our
                                Games and Website.
                            </p>
                        </text>
                        <br/>

                        <b>Acceptance of this Agreement with respect to minors</b>
                        <text>
                            <p>You may only accept the terms of this Agreement when you have reached the age of legal
                                capacity under
                                the applicable law (the laws of the country in which you reside). If you are under the
                                age of legal capacity,
                                please ask your parents or other legal representatives for assistance before using our
                                Games. By
                                continuing to use our Games, you certify that you have either reached the age of legal
                                capacity to accept
                                the terms of this Agreement, or your parent/legal representative has reviewed and agreed
                                to the terms of
                                the Agreement on your behalf.</p>
                            <p>To the extent permitted by law, CarX Technologies is not responsible for any actions that
                                may be
                                committed by minors without the permission of their parents or legal representatives.
                                Any use of the
                                Games and Website by minors is the responsibility of their parents or legal
                                representatives.</p>

                            <p>If you, as a parent or other legal representative, learn that your child has accepted the
                                terms of the
                                Agreement without your consent, please contact us (for example, via e-mail at <a
                                    href="mailto:support@carx-europe.com"> support@carx-europe.com</a> or other means
                                indicated on the Website).</p>
                            <p>We encourage you to monitor your children&apos;s play sessions and web activity, and to review
                                parental
                                control options that we and/or our partners may provide.</p>
                        </text>
                        <br/>

                        <b>Application of the Agreement</b>
                        <text>
                            <p>This Agreement applies to all CarX Technologies Games worldwide on all Platforms without
                                limitation,
                                regardless of how the Games are distributed on the Platforms (where the Platform may act
                                as a licensee
                                or agent of CarX Technologies).</p>
                            <p>If the Platform acts as a licensee of CarX Technologies and independently licenses the
                                Game to end users,
                                then the terms of this Agreement shall apply to the extent that this is not regulated by
                                the license
                                agreement or similar rules of the Platform.
                            </p>
                            <p>If the Platform licenses the Game to end-users on behalf of CarX Technologies, the
                                license agreement
                                or similar rules of the Platform apply only to the extent that the services and licenses
                                provided by the
                                Platform are affected.</p>
                        </text>
                        <br/>
                        <b>Amendment of the Agreement</b>
                        <text>
                            <p>The agreement located by the following address: <a
                                href={EULA_URL}
                                target="_blank"
                                rel="noreferrer"
                            > https://carx-europe.com/uploads/userfiles/EULA_CarX_ENG.pdf</a> is the most current
                                version. Over time, CarX
                                Technologies may unilaterally amend the Agreement, including to comply with the latest
                                changes in the
                                applicable law and jurisprudence. All changes come into force from the moment they are
                                published on
                                this page, unless a different period is indicated in the text of the amendments. CarX
                                Technologies will
                                make every effort to organize additional ways to notify Users of amendments to the
                                Agreement, but we
                                ask you to regularly check for the latest version on the Website yourself.</p>
                        </text>
                        <br/>

                        <b>Warnings prior to using the Games</b>
                        <text>
                            <p><b>Inconsistency of the gameplay and its elements with reality warning. </b>For
                                entertainment
                                purposes, extreme driving scenes may be shown in the Games. Please don&apos;t try this in
                                real life.</p>
                            <p><b>Potentially inappropriate content warning. </b>Our Games include features for User
                                communication (online chat) and provide the ability for Users to create their own
                                content in the
                                Games (User Content). These messages or content may be viewable by other Users. CarX
                                Technologies cannot perform pre-moderation functions but makes every possible effort to
                                hide
                                or remove content that does not comply with the law and the Agreement.</p>
                            <p>Given the sheer volume of posts and downloads, CarX Technologies cannot guarantee that
                                any
                                potentially inappropriate content will be promptly removed or hidden from other Users.
                                By using
                                our Games and accepting the Agreement, you acknowledge and agree that you may encounter
                                content that may be inappropriate or offensive to you.</p>
                            <p>In turn, if you encounter inappropriate content in our Games, please notify us by
                                e-mail <a
                                    href="mailto:support@carx-europe.com"> support@carx-europe.com </a> or in any other
                                way indicated on the Website.</p>
                            <p><b>Potential health hazards warning. </b>The Games may contain dramatically occurring
                                vivid,
                                photosensitive imagery that could potentially cause adverse feelings in a player of any
                                age,
                                regardless of health condition. Negative symptoms may include, for instance, dizziness,
                                impaired or blurred vision, eye or facial nerve twitching, trembling hands or feet,
                                disorientation, confusion or loss of consciousness, and seizures. <span>Stop playing
                            immediately if you experience any of these symptoms.</span></p>
                        </text>
                        <br/>

                        <b>ABOUT US</b>
                        <text style={{textAlign: 'left'}}>
                            <p>Car X Technologies EU Ltd</p>
                            <p>Reg.no 716662</p>
                            <p>Legal address: 5-7 O&apos;Connell street upper, Dublin 1, D01 K2W, Ireland</p>
                            <p>E-mail: <a href="mailto:support@carx-europe.com"> support@carx-europe.com</a></p>
                        </text>
                        <br/>

                        <b>DEFINITIONS</b>
                        <text>
                            <p>− <b>Account </b>is an account created by the User using the Device, which is a set of
                                data about the User
                                necessary for him to use the Game in line with this Agreement, namely: authorization
                                (authentication) of the User in the Game, access to information about the User,
                                settings, In-Game
                                Objects, statistical indicators and other similar information. The Account is intended
                                for storing the
                                User&apos;s personal data and for managing the options available in the Game.</p>
                            <p>− <b>CarX ID </b>is a functionality of the Game that allows registration of the User&apos;s
                                Account and its further
                                authorization without linking to the Device by entering the User&apos;s e-mail address and
                                password.
                                Thus, the CarX ID function provides the User with the opportunity to authorize, as well
                                as access
                                settings, information about the game process from another Device or, in case of
                                reinstalling the
                                Game, save the above-mentioned data.</p>
                            <p>− <b>Device </b>is a device from which you use our Games and/or Website. Devices include
                                mobile devices,
                                tablets, personal computers, consoles, etc.</p>
                            <p>− <b>DLC </b>is additional digital content that the User can purchase in addition to the
                                Game.</p>
                            <p>− <b>Game </b>is a game developed and published by CarX Technologies, regardless of the
                                Platform on
                                which it is distributed or the Devices for which it is developed.</p>
                            <p>− <b>In-Game Objects </b>are virtual objects in the Game, including in-game currency
                                (cash), skins, drift
                                boxes, fuel, body kits for customization, liveries, tracks, etc.</p>
                            <p>− <b>Licensed Content </b>is digital content created by third parties for which CarX
                                Technologies possess
                                licensee rights to use in the Games (includes but is not limited to tracks, music,
                                trademarks).</p>
                            <p>− <b>Platform </b>is an online store through which the Game is provided to the User. The
                                Platforms include,
                                in particular:</p>
                            <p style={{marginLeft: '2em'}}>● Stores for mobile versions of the Game: Google Play, App
                                Store, and Huawei App Gallery;</p>
                            <p style={{marginLeft: '2em'}}>● Store for PC versions of the Game: Steam;</p>
                            <p style={{marginLeft: '2em'}}>● Stores for console versions of the Game: PlayStation Store,
                                Nintendo eShop, Xbox Games
                                Store.</p>
                            <p>This list is not complete and can be changed from time to time.</p>
                            <p>− <b>Streaming </b>is a live video broadcast of the Game, which is held on a video
                                hosting or streaming
                                platform (e.g., YouTube or Twitch).</p>
                            <p>− <b>Subscription </b>is a period of access to the Games or premium sections of the
                                Games, provided subject
                                to periodic payments made by the User.</p>
                            <p>− <b>User-Generated Content </b>is digital content created by the User in connection with
                                the use of the
                                Game within its permitted functionality. It may include: any informational materials,
                                including
                                texts, graphics and other materials related to the Game (such as mods, custom stickers
                                for virtual
                                cars, vinyls, tuning, etc.), as well as chat messages with other Users and Streams.</p>
                            <p>− <b>Website </b>is a website at: <a
                                href="mailto:support@carx-europe.com"> support@carx-europe.com</a>.
                            </p>
                        </text>
                        <br/>

                        <b>1. Subject of the Agreement</b>
                        <p>1.1. CarX Technologies and its affiliates and partners own all intellectual property rights
                            to the Game,
                            the Website, as well as to all intellectual property objects included in the Game and the
                            Website,
                            including video and audio materials, computer code, names, objects, characters, slogans,
                            locations, illustrations, graphics, animation, musical compositions, audiovisual effects,
                            text,
                            screen images, trademarks, logos, other related documentation, and other included
                            content.</p>
                        <p>1.2. Upon acceptance of the terms of the Agreement, CarX Technologies grants the User the
                            right
                            to use the Games and the Website in their current version under a non-exclusive worldwide
                            license during the term of the Agreement for personal (non-commercial) purposes.</p>
                        <p>1.3. With respect to the Licensed Content within the Games, CarX Technologies grants a
                            nonexclusive
                            worldwide sub-license during the term of the Agreement in accordance with the terms
                            of the license agreement with the rights holders of the Licensed Content. The terms of the
                            Agreement apply to the Licensed Content to the same extent as our Games.</p>
                        <p>1.4. he scope of the license rights for the Game includes the as follows:</p>
                        <p style={{marginLeft: '2em'}}>Play the Game by downloading it to the Device;</p>
                        <p style={{marginLeft: '2em'}}>Use the Game for entertainment non-commercial (personal)
                            purposes, within which
                            the User is entitled to:</p>
                        <p style={{marginLeft: '3em'}}>● Create and delete an Account, if the functionality of a
                            particular Game
                            provides for this;</p>
                        <p style={{marginLeft: '3em'}}>● Use the functionality of the Game;</p>
                        <p style={{marginLeft: '3em'}}>● Purchase and exchange In-game Objects, if the functionality of
                            a
                            particular
                            Game provides for it, and use them;</p>
                        <p style={{marginLeft: '3em'}}>● Use the Licensed Content;</p>
                        <p style={{marginLeft: '3em'}}>● Use the functions of online chat and multiplayer;</p>
                        <p style={{marginLeft: '3em'}}>● Use the function of saving game progress;</p>
                        <p style={{marginLeft: '3em'}}>● Create User-Generated Content within the Games, if their
                            functionality
                            allows it, subject to the terms of the Agreement;</p>
                        <p style={{marginLeft: '3em'}}>● Participate in cyber sports events using the Game under the
                            terms provided
                            by this Agreement;</p>
                        <p style={{marginLeft: '3em'}}>● Organize cyber sports events using the Games, subject to the
                            terms provided
                            by this Agreement;</p>
                        <p style={{marginLeft: '3em'}}>● Use support services.</p>
                        <p> CarX Technologies may provide access to the Games or their full functionality subject to
                            certain
                            conditions, such as acceptance of the terms of the Agreement and the Privacy Policy. CarX
                            Technologies also has the right to terminate or restrict access to the Game at any time in
                            accordance with the terms of the Agreement.</p>
                        <p>1.5. The scope of license rights to the Website includes the following rights:</p>
                        <p style={{marginLeft: '2em'}}> Access the Website and use its functionality;</p>
                        <p style={{marginLeft: '2em'}}> View and distribute (without modification) the information
                            provided on the Website, if such
                            use of the information is carried out solely for non-commercial purposes and subject to the
                            preservation of all copyrights, related rights, trademark rights and other notices of
                            ownership
                            of intellectual property rights to CarX Technologies;</p>
                        <p style={{marginLeft: '2em'}}> Use support services.</p>
                        <p> CarX Technologies may provide access to the Website or certain features thereof subject to
                            certain conditions, such as agreeing to the use of cookies. CarX Technologies may also
                            terminate
                            or restrict access to the Website at any time in accordance with the terms of the
                            Agreement.</p>
                        <p>1.6. The rights to use the Game, In-Game Objects or some of its additional features may be
                            granted
                            to the User free of charge or for a fee (both in a fixed version and in the form of periodic
                            payments
                            (Subscription)). To determine which option is available, please refer to the Games page of
                            your
                            chosen Platform. Information about the cost of In-Game Objects or a Subscription for access
                            to
                            additional functionality is contained in the Game itself and its sections.</p>
                        <p>1.7. A license with respect to the Website is granted free of charge.</p>
                        <p>1.8. The Games provide functionality for creating User-Generated Content. Such User-Generated
                            Content, subject to sufficient creative and original input on your part, may be subject to
                            copyright
                            under applicable law. By accepting the Agreement, you acknowledge that if you create such
                            User-
                            Generated Content, you grant us a perpetual, non-exclusive license throughout the world in
                            respect of such copyrighted items. Subject to the terms of this license, CarX Technologies
                            will be
                            able to use them either with or without attribution (and by accepting this Agreement, you
                            authorize CarX Technologies to use the User-Generated Content anonymously without
                            attribution). CarX Technologies also has the right to modify, reproduce, distribute,
                            transfer to
                            third parties, publicly display, incl. on the Internet, use in advertising / marketing
                            materials,
                            process, create derivative works, translate, demonstrate to third parties, prohibit third
                            parties from
                            unauthorized use of such copyrighted items.</p>
                        <br/>

                        <b>2. Terms of use of the Games</b>
                        <p>2.1. In order to provide you with access to the Games, we need to access a limited list of
                            your
                            personal data. Without it, CarX Technologies cannot provide user support, display ads to
                            keep
                            some of our Games and their elements free to you, restore progress in the Games, analyze
                            statistics of our games to improve your user experience, fix bugs in the Games, determine
                            the
                            need for changes to our commercial decisions regarding the Games. Thus, for the purposes of
                            this Agreement, we need to have access to the personal data itself and the ability to share
                            it with
                            our partners, including cross-border. The processing of information provided by the User
                            about
                            himself or third parties when using the Games and the Website is carried out by us in
                            accordance with the Privacy Policy available at <a
                                href={POLICY_URL}
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://carx-europe.com/uploads/userfiles/Privacy_Policy_ENG.pdf
                            </a></p>
                        <p>2.2. Our Games have a certain age rating, which is indicated for each specific Game on the
                            Game
                            page on the Platform. Unless otherwise provided, In-Game Objects have the same age rating as
                            the Game. CarX Technologies may restrict access to the Games based on the age of our Users.
                            Minors under a certain age may be allowed access to the Games only with the consent of their
                            parents or legal representatives. Parents and legal representatives of minors may also
                            restrict
                            access to the Games by themselves by using the parental control functionality provided by
                            some
                            Platforms.</p>
                        <p>2.3. Our Games provide a multiplayer mode feature. This feature and its full functionality
                            may be
                            available either for free or for a fee (Subscription) depending on the Game and the Platform
                            where the Game has been downloaded. In order to fully access the functionality of the Games
                            in
                            multiplayer mode, the User must have a stable Internet connection. CarX Technologies is not
                            responsible for any problems with the use of the Games arising from problems with the
                            Internet
                            connection.</p>
                        <p>2.4. Our Games provide a save and progress management feature for connecting to the Game from
                            different Devices. This feature may be available provided the User is authorized in one of
                            the
                            functional ways available at the time of authorization. Without authorization, this feature
                            may be
                            limited and CarX Technologies may not be able to restore data related to your gameplay on
                            another Device.</p>
                        <p>2.5. Our Games have an online chat feature that allows Users to communicate with each other.
                            Chat
                            is pseudo-anonymous since CarX Technologies does not disclose Users&apos; real names (since we do
                            not collect such information ourselves), but it does show the nicknames of the
                            interlocutors. For
                            a mutually enjoyable gaming experience, CarX Technologies expects Users to comply with the
                            terms and restrictions of this Agreement when communicating.</p>
                        <p>We store a limited number of User messages (up to 100 recent messages), previous messages are
                            deleted automatically. In any case, information about the content of chat messages is stored
                            for
                            no more than 1 day.</p>
                        <p>2.6. Some of our Games provide an ad-viewing feature. This feature can be disabled by the
                            User,
                            including through the purchase of In-Game Objects (for example, in some Games, the purchase
                            of drift boxes disables advertising between races).</p>
                        <p>When viewing ads, you may see links to third-party Websites or applications. CarX
                            Technologies
                            cautions you that CarX Technologies is not responsible for, and has no control over, third
                            party
                            services and their data collection practice.</p>
                        <p>2.7. CarX Technologies stands for fair play and prohibits the use by Users of software
                            designed to
                            unfairly use the Game in relation to other players (“cheats”, “hacks”). By accepting the
                            Agreement, you agree not to use or facilitate the use by third parties of means aimed at
                            obtaining
                            an unfair advantage in the Game. CarX Technologies may use technical remedies to counter
                            such
                            behavior. You hereby also agree that you will not directly or indirectly interfere with the
                            operation
                            of such technical protections.</p>
                        <p>2.8. The user <b>is not entitled </b>to independently or with the involvement of third
                            parties:</p>
                        <p style={{marginLeft: '2em'}}>● to access and modify the source code of the Games in any
                            way;</p>
                        <p style={{marginLeft: '2em'}}>● Create software products and/or services using the Games and
                            their elements, reverse
                            engineer, decompile or otherwise modify the Games, either in whole or in part;</p>
                        <p style={{marginLeft: '2em'}}>● If the terms of use of the Platform provide for the possibility
                            of creating custom
                            modifications of the Game, the User is not entitled to distribute the Game along with users’
                            modifications and is not entitled to charge other users for the use of custom
                            modifications;</p>
                        <p style={{marginLeft: '2em'}}>● Develop, distribute or use “cheating” software (in order to
                            obtain benefits by fraudulent
                            means), use hacking programs, robots or other malicious programs;</p>
                        <p style={{marginLeft: '2em'}}>● Remove or alter in any way trademark and copyright notices or
                            any
                            other rights included
                            in the Games;</p>
                        <p style={{marginLeft: '2em'}}>● Use or copy the names, images of cars, tracks, characters,
                            texts
                            and audio and video
                            materials of the Games or any other intellectual property contained in the Games;</p>
                        <p style={{marginLeft: '2em'}}>● Redistribute In-Game Objects, Licensed Content and other
                            intellectual property from the
                            Games and the Website without permission from CarX Technologies and unless permitted
                            by the functionality of the Games;</p>
                        <p style={{marginLeft: '2em'}}>● Make transactions in relation to the Account, sell or otherwise
                            transfer it to other users of
                            the Games without the prior consent of CarX Technologies;</p>
                        <p style={{marginLeft: '2em'}}>● Distribute, transfer for use the Games or copies thereof;</p>
                        <p style={{marginLeft: '2em'}}>● Translate and localize the Games without the written consent of
                            CarX Technologies;</p>
                        <p style={{marginLeft: '2em'}}>● Download or transmit malicious software containing viruses,
                            corrupted files or data, or any
                            other similar software or items that may damage the operation of the Games or interrupt
                            any user&apos;s use of our Games;</p>
                        <p style={{marginLeft: '2em'}}>● Reporting false information to us or our customer service
                            team;</p>
                        <p style={{marginLeft: '2em'}}>● Use bugs or other unintended features of the Games, as well as
                            make changes that may
                            provide additional advantages in the Games that are not intended by us, take other actions
                            that may cause damage or discomfort to other players for the purpose of gaining an
                            advantage in a competition or for any other purpose;</p>
                        <p style={{marginLeft: '2em'}}>● Carry out any fraudulent/illegal activity that may lead to
                            false
                            payment in the Games;</p>
                        <p style={{marginLeft: '2em'}}>● Use the Games or the Website in a manner that violates any
                            applicable law or regulation,
                            violates any provision of the Agreement, or encourages or facilitates any illegal
                            activity.</p>
                        <br/>

                        <b>3. Terms of Use for In-Game Objects</b>
                        <p>3.1. The User has the right to purchase access to In-Game Objects. Some In-Game Objects may
                            be
                            available to the User free of charge (including as a reward for reaching a certain level),
                            for a fee
                            (either fixed or as a Subscription) or as a reward for viewing ads.</p>
                        <p>3.2. The rights to In-Game Objects, as additional intellectual property objects in relation
                            to the
                            Game, are granted under the terms of a non-exclusive license for their use. The User
                            understands
                            that while “obtaining”, “purchasing” and “acquiring” In-Game Objects, these terms are used
                            as
                            a convention only. Any number of In-Game Objects does not correspond to a credit balance in
                            real currency or its equivalent. In-Game Objects have no monetary value and cannot be
                            exchanged
                            for real money, real goods or real services.</p>
                        <p> 3.3. You may only purchase In-Game Objects from us or our authorized partners. Transactions
                            in
                            relation to In-Game Objects between the User and other users of the Game are prohibited,
                            except
                            for the situation when such functionality is provided in the Game. CarX Technologies has the
                            right to take all available legal measures to prevent unauthorized transactions with In-Game
                            Objects, including blocking Accounts in the Games, sending requests to third-party Websites
                            with a request to block information about the possibility of making such transactions,
                            etc.</p>
                        <p>3.4. We may limit the total number of In-Game Objects that can be purchased at one time
                            and/or
                            limit the total number of In-Game Objects your Account can contain in the aggregate.</p>
                        <p>3.5. We also reserve the right to monitor, regulate, modify or remove any In-Game Objects
                            without
                            liability at any time, but if you have a valid Subscription, such changes will only become
                            effective
                            for you upon the expiration of your current Subscription, after which your Subscription will
                            be
                            automatically terminated.</p>
                        <p>3.6. If CarX Technologies bans or deletes your Account in accordance with this Agreement, you
                            may lose In-Game Objects you have at the time of ban or deletion, and CarX Technologies will
                            not be obligated to compensate for these losses or make any refund in such event. If you
                            have an
                            active Subscription on the date the Account is deleted or blocked, it will not automatically
                            renew
                            at the end of the current Subscription.</p>
                        <p>3.7. Depending on the Platform through which you purchased access to the Game, the terms of
                            the
                            user agreements and terms of use of the Platforms may apply to any In-Game Objects. If you
                            are
                            unsure of the scope of your rights, you should contact the Platform or our customer support
                            prior to making a purchase.</p>
                        <p>3.8. All purchases of In-Game Objects are final and under no circumstances, including if a
                            portion
                            of the In-game Objects are duplicated as a result of a user&apos;s DLC purchase, shall such
                            In-Game
                            Objects, in whole or in part, be returned, transferred or exchanged.</p>
                        <br/>

                        <b>4. Terms of payments and refunds</b>
                        <p>4.1. CarX Technologies does not administer payment transactions on the Platform. All
                            purchases
                            are made through the Platform and are subject to the terms and conditions of the Platform.
                            Please note that in certain cases, the payment operator may charge a commission more than
                            the
                            established cost.
                        </p>
                        <p>4.2. Refunds cannot be made by CarX Technologies. To receive a refund for the purchase of In-
                            Game Objects or a Subscription, the User should contact the Platform.</p>
                        <p>4.3. The purchase of Games, Subscriptions and In-Game Objects is at the User&apos;s own will. All
                            purchases made using the User&apos;s Device are made by the User independently, regardless of who
                            initiated the purchase using the Device. CarX Technologies also encourages you to set up a
                            passcode for in-app purchases in your Device settings to prevent “accidental” purchases.</p>
                        <p>4.4. If you have contacted the Platform and received a refund for your purchases, CarX
                            Technologies
                            reserves the right:</p>
                        <p style={{marginLeft: '2em'}}>1) Remove In-Game Objects purchased with the refunded amount from
                            your Account;</p>
                        <p style={{marginLeft: '2em'}}>2) terminate the Subscription;</p>
                        <p style={{marginLeft: '2em'}}>3) suspend access to your Account/Game if refunds occur regularly
                            and/or for fraudulent
                            purposes.</p>
                        <br/>

                        <b>5. Rules of Conduct for Users in the Games</b>
                        <p>5.1. CarX Technologies responsibly treats the content of the Games and expects reciprocal,
                            responsible behavior from Users. CarX Technologies asks that you adhere to the rules to
                            ensure
                            safe user experience. The following is a list of prohibited activities. Doing so may result
                            in
                            temporary or complete termination of access to our Games and, as a result, termination of
                            the
                            Agreement.</p>
                        <p>5.2. <b>Activities prohibited in our Games.</b> These prohibitions apply to the choice of
                            nickname, team
                            name, room name and avatar, the User-Generated Content, chat messages and Streams. Any
                            action that:</p>
                        <p style={{marginLeft: '2em'}}>- violate the law or the Agreement, may propose actions that
                            potentially violate the law
                            or the Agreement,</p>
                        <p style={{marginLeft: '2em'}}>- contain profanity in any language, including in modified or
                            abbreviated form, as well as
                            explicit or implicit allusions to such profanity,</p>
                        <p style={{marginLeft: '2em'}}>- use language or content that is obscene, vulgar, hateful,
                            racist, sexist, or unethical and/or
                            otherwise offensive to other players or other persons or groups,</p>
                        <p style={{marginLeft: '2em'}}>- provide sexual references of an erotic or pornographic
                            nature,</p>
                        <p style={{marginLeft: '2em'}}>- express hostility on any grounds, justify extremism, terrorism,
                            fascism,</p>
                        <p style={{marginLeft: '2em'}}>- provide religious, social, or political connotations and can
                            lead to controversy on these
                            topics,</p>
                        <p style={{marginLeft: '2em'}}>- are intended to insult, discredit, slander or defame any
                            person, or contain threats or actions
                            that could be construed as sexual harassment,</p>
                        <p style={{marginLeft: '2em'}}>- violate any applicable intellectual property laws of CarX
                            Technologies, CarX
                            Technologies&apos; licensors with respect to the Licensed Content, or any other third party,
                            including copyrights, trademarks, trade secrets,</p>
                        <p style={{marginLeft: '2em'}}>- contain links to Websites containing viruses, phishing pages or
                            other variants of fraudulent
                            links and schemes,</p>
                        <p style={{marginLeft: '2em'}}>- are advertising, flood, spam, advertise or promote drugs and
                            resources containing such
                            information,</p>
                        <p style={{marginLeft: '2em'}}>- consist only of other people&apos;s names, well-known names, are the
                            name of the character of
                            another User or an employee or partner of CarX Technologies for the purposes of
                            imitation of personality.</p>
                        <p>This list of prohibitions is provided as an example and is not limited to the prohibitions
                            listed
                            above. CarX Technologies reserves the right to change and supplement it unilaterally.</p>
                        <p>5.3. While we value freedom of speech and expression, we must restrict the content that
                            violates the
                            above-mentioned provisions. CarX Technologies reserves the right to determine whether any
                            content or actions are consistent with the Agreement and CarX Technologies may, in its sole
                            discretion, remove such content, including User&apos;s online chat statements and communications,
                            suspend and/or terminate the Agreement with the relevant User without prior notice to the
                            User.</p>
                        <p>5.4. CarX Technologies reserves the right to consider each case on a case-by-case basis and
                            take any
                            action it deems necessary at its sole discretion. If you see something that you think
                            violates the
                            Agreement, please let us know by
                            e-mail <a href="mailto:support@carx-europe.com"> support@carx-europe.com</a> or in any other
                            way
                            indicated on the Website.</p>
                        <br/>

                        <b>6. Penalties for violation of the Agreement</b>
                        <p>6.1. You agree that your use of the Games and the Website shall be in accordance with the
                            Agreement. Violation of the Agreement may result in CarX Technologies being forced to
                            impose the following penalties on you:</p>
                        <p style={{marginLeft: '2em'}}>- removal of User Content that violates the terms of this
                            Agreement;</p>
                        <p style={{marginLeft: '2em'}}>- temporary and/or permanent suspension of access to the Game or
                            its functions (ban)
                            (automatic or at the discretion of a CarX Technologies representative);</p>
                        <p style={{marginLeft: '2em'}}>- modifying and/or deleting any In-Game Objects (including
                            stickers, liveries, etc.) that
                            may be associated with your Account;</p>
                        <p style={{marginLeft: '2em'}}>- reset and/or change any Game progress or benefits and
                            privileges that you have
                            achieved in our Games.</p>
                        <p>Penalties may be imposed without prior notice to the User.</p>
                        <p>In case of any evidence of an offense or crime, CarX Technologies may disclose data to law
                            enforcement.</p>
                        <p>6.2. The decision on the application and timing of penalties will be at our discretion.</p>
                        <p>6.3. To find out the reasons and the period of penalties imposed, please contact the support
                            service
                            by e-mail support@carx-europe.com or in any
                            other <a href="mailto:support@carx-europe.com"> support@carx-europe.com</a> or in any other
                            way
                            indicated on the Website.</p>
                        <br/>

                        <b>7. General rules for holding cyber sports tournaments</b>
                        <p>7.1. Cyber sports tournament series with respect to the Games may be organized either by CarX
                            Technologies or by third parties. Third party tournament organizers must comply with the
                            Agreement.</p>
                        <p>7.2. Tournaments may be held without any prior written consent of CarX Technologies only if
                            they
                            are held for non-commercial purposes and do not involve third parties as sponsors.
                            Tournaments held without prior written consent of CarX Technologies may not be considered
                            “official” tournaments for the Games and may not be officially endorsed by CarX Technologies
                            or use the trademarks or other designations of the Games and CarX Technologies. The
                            organizers of such tournaments are prohibited from taking actions that may mislead
                            participants
                            and any other third parties regarding the unofficial status of the event and the support of
                            CarX
                            Technologies. CarX Technologies is not responsible for any actions of the organizers of
                            tournaments that hold events without the prior consent of CarX Technologies, including to
                            their participants.</p>
                        <p>7.3. Upon receipt of a written consent of CarX Technologies, the tournament organizer is
                            granted a
                            revocable, royalty-free, non-exclusive license to use the Games in the territory of the
                            country
                            where the tournament is held and for the duration of the tournament, within and for the
                            purposes of the tournament.</p>
                        <p>7.4. The tournament organizer must independently develop the rules of the tournament,
                            including
                            ban rules, payment of cash prizes, if any, technical requirements, etc., and agree on them
                            with
                            CarX Technologies before the start of the tournament.</p>
                        <p>7.5. The tournament organizer is responsible for compliance by the participants, commentators
                            of
                            the tournament broadcasts with the following rules and requirements:</p>
                        <p style={{marginLeft: '2em'}}>7.5.1. Tournament participants must reach the minimum age for
                            participation in the
                            tournament allowed by applicable law and, if necessary, ensure that their legal
                            representatives agree to participate;</p>
                        <p style={{marginLeft: '2em'}}>7.5.2. Tournament participants are required to use only licensed
                            versions of the Games;</p>
                        <p style={{marginLeft: '2em'}}>7.5.3. Participants are prohibited from creating and/or using
                            bots, other programs, technical
                            and/or other tools that can make changes to the gameplay in games within the
                            framework of participating in tournaments;</p>
                        <p style={{marginLeft: '2em'}}>7.5.4. Tournament Participants are required to use Devices with
                            specifications above the
                            minimum system requirements established for specific CarX Technologies Games and
                            described on the pages of the relevant Games on the Platforms;</p>
                        <p style={{marginLeft: '2em'}}>7.5.5. The names of the racing cars of the tournament
                            participants must correspond to the
                            names of the cars used in the Game (to identify the participants, it is possible to use the
                            numbers of the pilots, the color of the car).</p>
                        <p style={{marginLeft: '2em'}}>7.5.6. Tournament participants are prohibited from:</p>
                        <p style={{marginLeft: '3em'}}>7.5.6.1. violate the rules of the Agreement, including the rules
                            of conduct in the
                            Games;</p>
                        <p style={{marginLeft: '3em'}}>7.5.6.2. comment or speak negatively about the Games, CarX
                            Technologies, its
                            partners and employees, other Users, the gaming industry and games from
                            other developers;</p>
                        <p style={{marginLeft: '3em'}}>7.5.6.3. use the tournament to organize illegal or
                            non-computer-related activities;</p>
                        <p style={{marginLeft: '3em'}}>7.5.6.4. misrepresent or attempt to misrepresent your age to CarX
                            Technologies or
                            a tournament organizer, or provide or attempt to provide invalid consent
                            from a legal representative;</p>
                        <p style={{marginLeft: '3em'}}>7.5.6.5. take part in a match from a Device on which root rights
                            or jailbreak are
                            installed.</p>
                        <p style={{marginLeft: '2em'}}>7.6. Persons who are employees of the organizer or sponsor of the
                            tournament (and any of their
                            affiliated organizations) hosting the current tournament are not allowed to participate in
                            the
                            tournament as players, unless otherwise provided by the rules of the tournament.</p>
                        <p style={{marginLeft: '2em'}}>7.7. When providing personal data of players to CarX
                            Technologies, the organizer is obliged to
                            ensure that consent to the processing of personal data from the players is obtained and
                            transferred to CarX Technologies.</p>
                        <p style={{marginLeft: '2em'}}>7.8. In case of violation of the rules by the tournament
                            participants and / or non-compliance of the
                            tournament participants with the requirements established in these rules, the tournament
                            organizer is obliged not to allow such a participant to participate in the tournament, and
                            if the
                            violation occurred after admission to the tournament, the tournament organizer is obliged to
                            exclude the violating person from among its members.</p>
                        <p style={{marginLeft: '2em'}}>7.9. Organizers of the tournament, participants and teams are
                            prohibited from receiving sponsorship
                            from persons associated with illegal activities, the use of alcohol, tobacco or drugs, the
                            field of
                            pornography or any other activity aimed only at adults.</p>
                        <p style={{marginLeft: '2em'}}>7.10. The tournament organizer is obliged to act as a tax agent
                            when granting cash prizes, if it is
                            allowed by the applicable law, as well as to carry out the necessary procedures of
                            identification
                            of recipients.</p>
                        <p style={{marginLeft: '2em'}}>7.11. If a tournament organizer fails to fulfill its obligations
                            or performs them improperly, cheats or
                            attempts to cheat, violates the rules of the Agreement, CarX Technologies has the right to
                            deprive such a tournament organizer of the opportunity to qualify for tournaments and
                            support
                            from CarX Technologies in the future. At the same time, such a decision regarding the
                            organizer
                            does not deprive CarX Technologies of the right to additionally recover the incurred losses
                            and
                            use other legal remedies.</p>
                        <br/>

                        <b>8. Limitation of liability</b>
                        <p>8.1. Our Games are complex multimedia products which require many hours of development.
                            However, technical difficulties may occur in any product of this level. As such, CarX
                            Technologies Games are provided on an “as is” basis, and CarX Technologies makes no
                            guarantees or warranties that they will be provided continuously, quickly, without “crashes”
                            or
                            errors.</p>
                        <p>8.2. There may be times when our Games or any of their functionality may not be available for
                            technical reasons. CarX Technologies is not responsible in any way for this, but we
                            undertake
                            to make every effort to eliminate errors that occur.</p>
                        <p>8.3. The Games and the Website may be updated, including by adding and expanding their
                            functionality, forms, nature, or content without prior notice to Users. CarX Technologies
                            has
                            the right, in its sole discretion, to terminate (temporarily or permanently) the support
                            and/or
                            provision of the Games and the Website or any of their elements.</p>
                        <p>8.4. To the extent permitted by applicable law, CarX Technologies shall not be liable to you
                            for any
                            direct or consequential damages, including but not limited to loss of revenue, loss of
                            profits,
                            loss of data, or other intangible damages (regardless of the qualification of such damages)
                            arising
                            out of or in connection in any way with this Agreement.</p>
                        <p>8.5. The amount payable by CarX Technologies to the User may not exceed the amount of
                            remuneration received by CarX Technologies from the User for the period of three (3) months
                            immediately preceding the date of the relevant claim. The User also agrees that in the
                            absence
                            of payments made to CarX Technologies as a reward for using the Game during the specified
                            period, the User&apos;s sole remedy for any disagreement with CarX Technologies is to stop using
                            the Game and delete the Account.</p>
                        <p>8.6. If applicable law does not allow the above limitations of liability to apply between the
                            User and
                            CarX Technologies in whole or in part, the limitations of liability will apply only to the
                            extent
                            permitted by applicable law.</p>
                        <p>8.7. CarX Technologies shall be exempt from liability for defaults due to unforeseen
                            circumstances
                            (force majeure) such as acts of God, terrorism, war, riots, embargoes, epidemics, acts of
                            government, fire, floods, accidents, strikes, shortages of vehicles, acts of malware, and
                            unscrupulous acts of third parties resulting from unauthorized access or disabling of CarX
                            Technologies software, Platforms or CarX Technologies partners.</p>
                        <p>8.8. CarX Technologies is not responsible for: the behavior of users in rating races,
                            championships;
                            user ratings/achievements obtained using actual in-game mechanics; ratings/achievements of
                            users obtained by fraud (including cheating). At the same time, if it is discovered that a
                            User has
                            obtained advantages in the Game by fraudulent means, CarX Technologies may apply measures
                            against such users provided for in Section 6 of this Agreement.</p>
                        <br/>

                        <b>9. User Responsibility</b>
                        <p>9.1. The User shall be liable for any breach of this Agreement, including violations of
                            applicable law,
                            and for all consequences of such breaches (including any damages that CarX Technologies and
                            other third parties may suffer).</p>
                        <p>9.2. CarX Technologies is not affiliated with User-Generated Content. The User who created
                            the
                            User-Generated Content and/or added it to the Games is personally responsible for its
                            compliance with the requirements of applicable law. CarX Technologies has the right to block
                            or remove User-Generated Content that does not comply with the requirements of applicable
                            law and the Agreement, at its sole discretion.</p>
                        <p>9.3. User agrees to indemnify CarX Technologies, its affiliates, partners, third party
                            suppliers and
                            providers, licensors, officers, directors, employees, distributors and agents against any
                            losses,
                            damages, liabilities, penalties, bills and expenses (including legal fees and costs) in
                            connection
                            with any claim or action that <b>(a)</b> arises from any actual or alleged violation of this
                            Agreement
                            by User, <b>(b)</b> arises from the provision of incomplete or inaccurate
                            information; <b>(c)</b>
                            arises from
                            the use of any third party websites or services; or <b>(d)</b> otherwise arises out of or in
                            connection
                            with the User&apos;s use of the Games, and agrees to indemnify for damages resulting from such
                            violations.</p>
                        <p>9.4. CarX Technologies shall be entitled to claim damages when the User uses the Games for
                            illegal
                            purposes, in an illegal manner or in a manner inconsistent with the terms of the Agreement,
                            and
                            such damages may include, but are not limited to, direct, indirect, special, incidental,
                            and/or
                            consequential losses. If CarX Technologies is required to respond to a third-party claim or
                            law
                            enforcement request or court order (or other decision) that is related to the User&apos;s use of
                            the
                            Games, CarX Technologies may, in its sole discretion, require the User to reimburse the
                            costs
                            associated with filing a claim or request, in reasonable size.</p>
                        <p>9.5. We reserve the right, at our sole discretion, to prosecute infringers of intellectual
                            property rights
                            in the Games or Website in accordance with applicable civil, administrative, and criminal
                            law.</p>
                        <br/>

                        <b>10. Agreement termination</b>
                        <p>10.1. <b>Termination initiated by CarX Technologies.</b> CarX Technologies has the right to
                            terminate the Agreement with the User unilaterally at any time and at any time restrict the
                            User&apos;s
                            access to the Game, including the In-Game Objects or the Website in case of any violation by
                            the User of the Agreement.</p>
                        <p>In addition, CarX Technologies may decide to discontinue access to the Games or support for
                            a particular Game in its sole discretion. This will automatically terminate the Agreement.
                            CarX
                            Technologies undertakes to make every effort to notify Users within a reasonable time prior
                            to
                            such termination. Given that CarX Technologies does not collect player contact information,
                            we do not guarantee targeted notification of each of the users, however, we undertake to
                            disseminate such information in the resources available to us, including our Website and
                            social
                            networks.</p>
                        <p>10.2. <b>Termination initiated by User.</b> The User has the right to terminate the Agreement
                            unilaterally
                            at any time without notice to CarX Technologies by deleting the Account and / or the game
                            client from the Device or stopping using the Website. In these cases, access to In-Game
                            Objects
                            may be restricted without the right to restore. In the event of termination of the Agreement
                            at
                            the initiative of the User, CarX Technologies will not provide the User with a refund for
                            the
                            purchase of In-Game Objects and will not compensate for any losses. Unilateral termination
                            of
                            the Agreement by the User does not release the User from liability for violations of the
                            Agreement committed during the period of its validity.</p>
                        <br/>

                        <b>11. Dispute resolution</b>
                        <p>11.1. <b>Applicable Law.</b> The Agreement, and any disputes relating to the Games and the
                            Website
                            that are not covered by the Agreement, are subject to and governed by the applicable laws of
                            the Russian Federation. If the laws of your country prohibit us from enforcing the
                            provisions
                            of this clause, CarX Technologies will follow the rules established by the laws of your
                            country.</p>
                        <p>11.2. The parties agree to first attempt to resolve disputes by negotiation. Users should
                            contact the
                            support service via e-mail at <a
                                href="mailto:support@carx-europe.com"> support@carx-europe.com</a> or by any other means
                            indicated on the
                            Website indicating that the matter should be brought to the attention of the CarX
                            Technologies
                            legal team. The CarX Technologies legal team must provide a written response within 10
                            business days via the User&apos;s means of communication specified in the request.</p>
                        <p>If the dispute cannot be resolved by negotiation, you agree to the exclusive jurisdiction and
                            venue of the appropriate court located in Moscow, Russian Federation, unless otherwise
                            provided by other applicable law.</p>
                        <br/>

                        <b>12. Provisions applicable to residents of the European Union</b>
                        <p>12.1. If you are in a member state of the European Union, you have the right to cancel any
                            purchase
                            within 14 days without giving a reason. To exercise your right of withdrawal, you must
                            inform
                            your Platform, as CarX Technologies does not administer payments and refunds. You may use
                            the opt-out form below as a sample:</p>
                        <p style={{marginLeft: '2em'}}>- I hereby give notice that I am terminating my contract as
                            follows: [INSERT ORDER ID, ITEM]</p>
                        <p style={{marginLeft: '2em'}}>- Ordered [INSERT DATE] / Received [INSERT DATE]</p>
                        <p style={{marginLeft: '2em'}}>- User&apos;s Name</p>
                        <p style={{marginLeft: '2em'}}>- User&apos;s email address</p>
                        <p style={{marginLeft: '2em'}}>Date</p>
                        <p>To exercise your right of withdrawal in a timely manner, you must contact the Platform prior
                            to the expiration of your right of withdrawal. If you exercise your statutory right of
                            withdrawal,
                            you will be refunded without undue delay and in any event no later than 14 days from the
                            date
                            the Platform is informed of your decision to withdraw.</p>
                        <p>12.2 Under EU law, CarX Technologies is liable under the law for (a) intentional misconduct;
                            (b)
                            gross negligence; (c) violation of applicable product liability laws. Subject to this, CarX
                            Technologies may only be liable for breach of a material contractual obligation under the
                            Agreement, breach of which would jeopardize the purpose of the Agreement. In such an event,
                            CarX Technologies&apos; liability is common in such situations and foreseeable damages. In other
                            circumstances, CarX Technologies shall not be liable for acts of negligence.</p>
                        <p>12.3. The User agrees that any dispute arising in connection with the Games, or in any way
                            related
                            to them, may be resolved through negotiations, or using the European Online Dispute
                            Resolution Platform (“<b>ODR Platform</b>”).</p>
                        <p>The ODR platform is specifically designed to assist consumers who purchase goods or services
                            online and encounter problems with such online purchases, and allows contractual disputes to
                            be resolved through the Alternative Dispute Resolution (ADR) procedure online at <a
                                href='https://Webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.chooseLanguage'
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://Webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.chooseLanguage
                            </a>
                           </p>
                        <p>If you cannot resolve the dispute through negotiation, failure to approve an amicable
                            settlement,
                            or failure to resolve the dispute through ADR, you have the right to file a lawsuit in the
                            regular
                            course of action.</p>
                        <br/>

                        <b>13. Miscellaneous</b>
                        <p>13.1 <b>Term of the Agreement.</b> The Agreement comes into force from the moment of
                            acceptance
                            of its terms or from the moment of the first use of the Game, depending on which event
                            occurs
                            earlier, and remains in force during the entire period of use of the Games or the Website by
                            the
                            User. Some provisions of the Agreement (based on their purpose) may be valid after the end
                            of
                            the last gaming session of the User.</p>
                        <p>13.2 <b>Invalidity.</b> If one or more provisions of this Agreement are held invalid by an enforceable
                            court decision in a dispute between the User and CarX Technologies, the remaining provisions
                            of this Agreement shall remain in effect for the User and CarX Technologies.</p>
                        <p>13.3 <b>Waiver.</b> If CarX Technologies fails to timely enforce the terms of this Agreement, it shall in
                            no way be construed as a waiver of CarX Technologies&apos; past or future obligations under this
                            Agreement.</p>
                        <p>13.4 <b>Assignment of rights.</b> CarX Technologies may transfer (assign) any or all the rights and/or
                            obligations contained in the Agreement to any third party. You may not assign (transfer) any or
                            all the rights and/or obligations contained in the Agreement to any third party.</p>
                        <br/>
                    </div>
                </div>
            </div>

        </>
    )
}

export default EULA;