import React from "react";
import './Footer.scss';
import {GetCurrentYear} from "./GetCurrentYear";
import {Link} from "react-router-dom";


const Footer = (props) => {
    return (
        <footer>
            <div className="copyright">

                <span>CarX Technologies EU Ltd.</span><br/>
                <span>© 2022 — <GetCurrentYear/></span>
                <Link className="link" to={'/carx-technologies-privacy-policy'} target="_blank" rel="noopener noreferrer">Privacy Policy</Link>
                <Link className="link" to={'/carx-technologies-eula'} target="_blank" rel="noopener noreferrer">EULA</Link>
            </div>
        </footer>
    )
}

export default Footer;